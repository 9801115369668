export const APP_CONST = Object.freeze({
  AUTH_ACTIONS: {
    LOGIN: "login",
    REGISTER: "register",
  },
  TOAST_TYPE: {
    ERROR: "error",
    SUCCESS: "success",
  },
  SPORTS_ID: {
    CIRCKET: "4",
    SOCCER: "1",
    TENNIS: "2",
  },
  ALL_MARKET_IDS: {
    CRICKET: "CRICKET_IDS",
    SOCCER: "SOCCER_IDS",
    TENNIS: "TENNIS_IDS",
  },
  ODDS_TYPE: {
    BACK: "back",
    LAY: "lay",
  },
  PLAYER_BANK_DETAILS_TYPE: {
    Bank: "bank",
    Card: "card",
  },
  TEST: {},
  AUTH_MODAL: {
    LOGIN: "login",
    REGISTER: "register",
    MOBILE_NUMBER: "mobileNumber",
    VERIFY_NUMBER: "verifyNumber",
    FORGOTO_PASSWORD: "forgotpassword",
    RESET_PASSWORD: "resetpassword",
  },
  AUTH_MODAL_ACTION_TYPE: {
    MANUAL: "manual",
    AUTO: "auto",
  },
  ORGANISATION_STATUS: {
    Maintenance: "maintenance",
    Live: "live",
    Suspended: "suspended",
  },
});

export const APP_HOST = "localhost:3000";

export const SIKKIM_COORDINATES = [
  [88.23083076877835, 27.85191714035538],
  [88.07833211240867, 27.508260520274074],
  [88.07977195066582, 27.421074192551277],
  [88.05058469367633, 27.389378530103862],
  [88.07097423181042, 27.34069130723402],
  [88.04976613516331, 27.288556087769354],
  [88.0270063540189, 27.228729172815246],
  [88.06858792502183, 27.2186552532429],
  [88.09737602666763, 27.18239541521008],
  [88.09943079831237, 27.137093873991255],
  [88.14425854846446, 27.12000791847437],
  [88.18513987778945, 27.136549940772813],
  [88.2187650020727, 27.135787475858827],
  [88.23582564900263, 27.12118059163756],
  [88.25372059847251, 27.130707295485784],
  [88.27217032206774, 27.134098299051477],
  [88.31014502426609, 27.134402197214726],
  [88.36156407702896, 27.103964077317947],
  [88.43732220094562, 27.08863748664872],
  [88.4905851033322, 27.138502322880385],
  [88.54765426713627, 27.19065544656631],
  [88.60244565957338, 27.196091817281626],
  [88.6494704725418, 27.167638411766305],
  [88.68140734374745, 27.183036670867057],
  [88.74108241530854, 27.165585287018267],
  [88.79079564387342, 27.26300694768027],
  [88.89736370450782, 27.295724594222904],
  [88.78219491563115, 27.41730289508183],
  [88.76090975551, 27.567718263928022],
  [88.78475736530964, 27.60060285428538],
  [88.82791737971542, 27.66769241563172],
  [88.84884368752735, 27.77994955112893],
  [88.874910808602, 27.884298822572802],
  [88.83365629826139, 27.92620414002513],
  [88.83686485853269, 27.971935262276688],
  [88.81637825209692, 28.01443325634768],
  [88.73295281612371, 28.061647939009614],
  [88.61087083219785, 28.079088861406447],
  [88.56177118056164, 28.02375884214543],
  [88.16369600776561, 27.916803952265745],
  [88.23083076877835, 27.85191714035538],
];

// export const SIKKIM_COORDINATES = [
//   [73.8, 29.5], // Bottom-left corner
//   [76.0, 29.5], // Bottom-right corner
//   [76.0, 32.5], // Top-right corner
//   [73.8, 32.5], // Top-left corner
//   [73.8, 29.5], // Close the polygon
// ];
