import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logoutUser } from "../redux/auth/actions";
import { Dropdown, Button, Modal } from "react-bootstrap";
import numberWithCommas from "../utils/numbersWithComma";

import DepositIcon from "../assets/images/icons/deposit-icon.webp";
import Logo from "../assets/images/logo.webp";
import RupeeIcon from "../assets/images/icons/rupee-icon.svg";
import ArrowDown from "../assets/images/icons/arrow-down.webp";
import UserIcon from "../assets/images/icons/user-icon.webp";
import AviatorLogo from "../assets/images/icons/aviator-logo.webp";
import ArrowRight from "../assets/images/icons/arrow-right.webp";
import GiftIcon from "../assets/images/icons/gift-icon.webp";
import Bonuses from "../assets/images/icons/bonuses.webp";
import GamesSearchModal from "../views/components/GamesSearchModal";

const HeaderAfterLogin = () => {
  const { user, wallet, exposureAmount, balance } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);

  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const headerPages = [
    "home",
    "betby",
    "sports",
    "casinogames",
    "livecasino",
    "aviator",
    "evolution",
    "ezugi",
    "supernowa",
    "vivo",
    "xpg",
    "worldcasino",
    "qtech",
    "/",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  const [isSticky, setIsSticky] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      if (currentScrollTop > lastScrollTop && currentScrollTop > 0) {
        // Scrolling down
        setIsSticky(true);
      } else if (currentScrollTop <= 0) {
        // Remove sticky class when scrolled all the way up
        setIsSticky(false);
      }
      setLastScrollTop(currentScrollTop);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  const [isproviderActive, setIsproviderActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const inlineStyle = isproviderActive ? { overflowX: "clip" } : {};
  const handleProvidersClick = () => {
    const isMobile = window.innerWidth < 992;

    if (isMobile) {
      setShowModal(true);
      document.body.classList.add("modal-providers-aftrlgn");
      document.documentElement.style.overflow = "hidden";
    } else {
      setIsproviderActive(!isproviderActive);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    document.body.classList.remove("modal-providers");
    document.documentElement.style.overflow = "auto";
  };

  const menuItems = [
    { name: "Home", path: "/", activeKey: "home" },
    { name: "Sports", path: "/betby", activeKey: "betby" },
    { name: "Exchange", path: "/sports/Inplay", activeKey: "sports" },
    { name: "Casino", path: "/casinogames", activeKey: "casinogames" },
    { name: "Live-games", path: "/livecasino", activeKey: "livecasino" },
    {
      name: "Aviator",
      path: "casino/spribe/aviator",
      activeKey: "aviator",
      isLogo: true,
      logo: AviatorLogo,
    },
  ];
  const providers = [
    { name: "Evolution", path: "/casino/evolution", activeKey: "evolution" },
    { name: "Ezugi", path: "/casino/ezugi", activeKey: "ezugi" },
    { name: "Supernowa", path: "/casino/supernowa", activeKey: "supernowa" },
    { name: "Vivo", path: "/casino/vivo", activeKey: "vivo" },
    { name: "XPG", path: "/casino/xpg", activeKey: "xpg" },
    {
      name: "Worldcasino",
      path: "/casino/worldcasino",
      activeKey: "worldcasino",
    },
    { name: "Qtech", path: "/casino/qtech", activeKey: "qtech" },
  ];

  return (
    <header className="header aftrlgn">
      <div className="top_head d-none d-lg-block">
        <div className="inner-box">
          <div className="left-section">
            <a href="#" className="vip-level">
              <span>Welcome Bonus</span>
              <span className="vip-icon"></span>
              <img src={ArrowRight} alt="right arrow" />
            </a>
          </div>

          <div className="right-section">
            <div className="vip-gift">
              <a href="#" className="promotion">
                <div className="img-box">
                  <img src={GiftIcon} alt="promotions" />
                </div>
                <span>Promotions and bonuses</span>
                <img src={Bonuses} alt="bonuses" className="bonus" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={`bottom_head ${isSticky ? "sticky" : ""}`}>
        <div className="inner-box">
          <div className="logo">
            <a href="/">
              <img src={appDetails?.LOGO_URL} alt="Logo" height={60} />
              {/* <img src={Logo} alt="Logo" /> */}
            </a>
          </div>

          <div className="head-links">
            <ul style={inlineStyle}>
              {menuItems.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.path}
                    className={`
                      ${
                        location.pathname === item.path ||
                        activePage === item.activeKey
                          ? "active"
                          : ""
                      }
                      ${item.name === "Aviator" ? "game-logo" : ""}
                    `}
                  >
                    <span>
                      {item.isLogo ? (
                        <img src={item.logo} alt={item.name} />
                      ) : (
                        item.name
                      )}
                    </span>
                  </a>
                </li>
              ))}
              <li className="providers">
                <a href="javascript:void(0)" onClick={handleProvidersClick}>
                  <span>Games by provider</span>
                </a>
                <ul
                  className={`providers-games d-none d-md-flex ${
                    isproviderActive ? "active" : ""
                  }`}
                >
                  {providers.map((provider) => (
                    <li key={provider.name}>
                      <a
                        href={provider.path}
                        className={
                          activePage === provider.activeKey ? "active" : ""
                        }
                      >
                        <span>{provider.name}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>

            <div className="d-lg-none">
              <GamesSearchModal />
            </div>
          </div>

          <div className="rightSec ms-lg-auto">
            <div className="bal-dpst">
              <div className="balance">
                <span>
                  INR
                  <Dropdown align="center" className="balanceDropdown">
                    <Dropdown.Toggle variant="success" id="dropdown-balance">
                      <img src={ArrowDown} alt="balance" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="bal real">
                        <p>Main wallet</p>
                        <div className="box">
                          <div className="img-box">
                            <img src={RupeeIcon} alt="rupee" />
                          </div>
                          <div className="amnt">
                            {numberWithCommas(wallet?.balance)}
                          </div>
                          <div className="dpst">
                            <a href="/gatewaylist">
                              <img src={DepositIcon} alt="deposit" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="bal cash">
                        <p>Cash</p>
                        <div className="box">
                          <div className="img-box">
                            <img src={RupeeIcon} alt="rupee" />
                          </div>
                          <div className="amnt">
                            {numberWithCommas(wallet?.cash)}
                          </div>
                        </div>
                      </div>
                      <div className="bal bonus">
                        <p>Bonus</p>
                        <div className="box">
                          <div className="img-box">
                            <img src={RupeeIcon} alt="rupee" />
                          </div>
                          <div className="amnt">
                            {numberWithCommas(wallet?.coins)}
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
                <strong>{wallet?.balance}</strong>
              </div>

              <div className="dpst-btn">
                <a href="/gatewaylist" className="btn">
                  Deposit
                </a>
              </div>
            </div>

            <Dropdown align="end" className="userDropdown">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={UserIcon} alt="user" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                <Dropdown.Item href="/withdraw">Withdrawal</Dropdown.Item>
                <Dropdown.Item href="/mybets">Bets History</Dropdown.Item>
                <Dropdown.Item href="/referral">Referral</Dropdown.Item>
                <Dropdown.Item href="/bonus">Bonus</Dropdown.Item>
                <Dropdown.Item href="/cashier">Statement</Dropdown.Item>
                <Dropdown.Item href="/" onClick={() => dispatch(logoutUser())}>
                  Log Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      <Modal
        className="providers-list"
        show={showModal}
        onHide={handleModalClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          <div className="providers-link">
            <ul>
              {providers.map((provider) => (
                <li key={provider.name}>
                  <a
                    href={provider.path}
                    className={
                      activePage === provider.activeKey ? "active" : ""
                    }
                  >
                    <span>{provider.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </header>
  );
};

export default HeaderAfterLogin;
